<template>
    <div id="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <Hero />
    <Services />
    <div class="container" >
      <Packages />
    </div>
      <ServiceSteps />
      <TestimonialsSeconde />
    <div class="container">
      <Blogs />
      <Partner />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Home',
  data(){
    return{
    }
  },
  components: {
    Hero: defineAsyncComponent( () => import('@/components/Home/Hero.vue') ),
    Services: defineAsyncComponent( () => import('@/components/Home/Services.vue') ),
    Packages: defineAsyncComponent( () => import('@/components/Home/Packages.vue') ),
    ServiceSteps: defineAsyncComponent( () => import('@/components/Home/ServiceSteps.vue') ),
    TestimonialsSeconde: defineAsyncComponent( () => import('@/components/Home/TestimonialsSeconde.vue') ),
    Blogs: defineAsyncComponent( () => import('@/components/Home/Blogs.vue') ),
    Partner: defineAsyncComponent( () => import('@/components/Home/Partner.vue') ),
  },
 
}
</script>
